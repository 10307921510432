import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import type { DestinationFragment, Maybe, Message, StockFragment } from '../graphql/__generated__'
import { formatPrice } from '../utils/Price';
import GiftMessagePreview from './GiftMessagePreview';
import CoverFormInput, { CoverFormFields } from './CoverFormInput';
import { classNames } from '../utils/classes';
import { MessageFormOptions } from './MessageForm';
import { isCustomCover } from '../utils/Covers';

export type MessageFormFields = {
  giftMessage: Maybe<Message>,
} & CoverFormFields

export type GiftMessageInputProps = {
  isEGift: boolean;
  stock: StockFragment | undefined;
  destination: DestinationFragment | undefined;
} & Pick<MessageFormOptions, 'onCustomCoverEdit' | 'choice' | 'setChoice'>

export default function GiftMessageInput({ isEGift, stock, destination, onCustomCoverEdit, choice, setChoice }: GiftMessageInputProps) {
  const { register, watch } = useFormContext<MessageFormFields>()

  const isCustomFrontCover = destination?.coverId && isCustomCover(destination.coverId)
  const isBlankFrontCover = destination?.coverId === 'cover-blank'
  const onlyShowGiftMessage = isEGift || (!isCustomFrontCover && !isBlankFrontCover)
  useEffect(() => {
    if (onlyShowGiftMessage) {
      setChoice('message')
    }
  }, [onlyShowGiftMessage]);

  const plainTextMessageInput = (<div className={ classNames(
    'w-[30rem] my-2 max-w-full',
    onlyShowGiftMessage ? 'mx-auto' : '',
  ) }>
    <div className="shadow overflow-hidden sm:rounded-md">
      <div className="px-4 py-5 bg-white sm:p-6">
        <div className="flex flex-col">
          <label className="block text-sm font-medium text-gray-700" htmlFor="giftMessage.body">
            Gift Message
          </label>
          <textarea
            {...register("giftMessage.body")}
            className="
              mt-1 block w-full h-24 p-2 text-sm
              text-gray-700 border border-gray-300 rounded-md"
            placeholder="Enter a message for the recipient"
          />
        </div>
      </div>
    </div>
    { !isEGift && <GiftMessagePreview message={ watch('giftMessage.body') } /> }
  </div>)

  if (onlyShowGiftMessage) {
    return (
      <div className="relative">
        <div className="mb-4">
          {plainTextMessageInput}
        </div>
      </div>
    );
  }

  let customDesignCost = 2000
  if (destination?.coverId && isCustomCover(destination.coverId)) {
    customDesignCost = 1000
  }

  return (
    <>
      <div className="flex gap-4 md:gap-6">
        <div className="basis-full">
          <div
            className={ classNames(
              'cursor-pointer m-auto py-4 md:py-6 text-center rounded border-4 border-pink-300',
              choice === 'message' ? 'bg-pink-100 border-green-300' : 'hover:border-green-500',
            ) }
            onClick={ (e) => {
              e.preventDefault()
              setChoice(prev => prev === 'message' ? null : 'message')
            } }
            data-cy="gift-message-button"
          >
            <div className="text-center">
              <h2 className="inline-block text-lg font-bold text-gray-900 px-4 md:px-6 w-36 md:w-auto">Basic Text (free)</h2>
            </div>
            <div className="text-right">
              <img className="inline-block h-3/4 sm:h-1/2 lg:h-auto" style={ { maxHeight: "250px" } } src={ `${ process.env.PUBLIC_URL }/images/example-message.png` } />
            </div>
            <div className="flex items-center h-32 sm:h-auto px-4 md:px-6">
              <p className="text-gray-600">Your message printed on a sticker, placed on the inside cover of the book.</p>
            </div>
          </div>
          <div
            className={ classNames(
              'w-0 h-0 mt-2 m-auto border-[40px] border-solid border-transparent border-b-0 border-t-pink-300',
              choice === 'message' ? 'block' : 'hidden',
            ) }
          ></div>
        </div>

        <div className="basis-full">
          <div
            className={ classNames(
              'cursor-pointer m-auto py-4 md:py-6 text-center rounded border-4 border-pink-300',
              choice === 'custom' ? 'bg-pink-100 border-green-300' : 'hover:border-green-500',
            ) }
            onClick={ (e) => {
              e.preventDefault()
              setChoice(prev => prev === 'custom' ? null : 'custom')
            } }
          >
            <div className="text-center">
              <h2 className="inline-block text-lg font-bold text-gray-900 px-4 md:px-6 w-36 md:w-auto">Full Color <span className="whitespace-nowrap">(only { formatPrice(customDesignCost) })</span></h2>
            </div>
            <div className="text-right">
              <img className="inline-block h-3/4 sm:h-1/2 lg:h-auto" style={ { maxHeight: "250px" } } src={ `${ process.env.PUBLIC_URL }/images/custom-inside-cover.png` } />
            </div>
            <div className="flex items-center h-32 sm:h-auto px-4 md:px-6">
              <p className="text-gray-600">Your full-color design printed directly on the inside cover.</p>
            </div>
          </div>
          <div
            className={ classNames(
              'w-0 h-0 mt-2 m-auto border-[40px] border-solid border-transparent border-b-0 border-t-pink-300',
              choice === 'custom' ? 'block' : 'hidden',
            ) }
          ></div>
        </div>
      </div>
      
      { choice === 'message' && plainTextMessageInput }
      { choice === 'custom' && (
        <div className="mx-auto md:mx-4 space-y-4 my-2">
          <CoverFormInput
            destinationId={ destination?.id }
            coverId={ destination?.insideCoverId }
            onCustomCoverEdit={ onCustomCoverEdit }
            stock={ stock }
            onlyCustom hidePrices
          />
        </div>
      ) }
    </>
  )
}
